.eventDetails {
    padding: 80px 0;
}

.eventDetails .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.eventDetails .container h1 {
    font-size: 42px;
    font-weight: 900;
    color: #000;
    position: relative;
    margin-bottom: 20px;
    text-align: center;
}

.eventDetails .container h1::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -18px;
    width: 50%;
    height: 1px;
    background: #707070;
    z-index: 1;
}

.eventDetails .container h1::before {
    position: absolute;
    content: "";
    left: 5%;
    bottom: -20px;
    width: 95%;
    height: 6px;
    background: #e3bf5c;
    z-index: 2;
}

.EventCard_titleAr::after {
    right: 0 !important;
}

.EventCard_titleAr::before {
    right: 5% !important;

}

.eventDetails img {
    max-height: 500px;
}

.eventDetails p {
    text-align: center;
    font-size: 24px;
    color: #484848;
}

@media(max-width: 767px) {
    .eventDetails .container h1 {
        font-size: 24px;
    }

    .eventDetails img {
        max-height: 300px;
    }

    .eventDetails p {
        font-size: 16px;
    }
}
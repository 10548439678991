.header {
    /* padding: 1rem 0; */
    width: 100vw;
    padding: 1rem 0;
    transition: all 0.3s;
    background: transparent url("../../assets/images/headerbg.svg") 0% 0% no-repeat padding-box;
    /* background: rgba(32, 56, 72, .4); */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
}

.header .container .rside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgLogo a {
    text-decoration: none;
}

.imgLogo span {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 17px;
}

.imgLogo img {
    width: 150px;
}

.navbar nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.navbar nav ul li a {
    text-decoration: none;
    font-size: 21px;
    letter-spacing: 0px;
    color: #ffffff;
    transition: all 0.3s;
}

.navbar>nav>ul>li:hover>a {
    color: #e3bf5c;
    font-size: 20px;
}

.activeNav {
    font-size: 20px !important;
    color: #e3bf5c !important;
}

.btnTrain a {
    border: none;
    outline: none;
    text-decoration: none;
    color: #4381c2;
    background: var(--color-text);
    font-size: 18px;
    font-weight: 600;
    padding: 4px 40px 5px;
    box-shadow: -3px 4px 0px #4381c2;
    letter-spacing: 0px;
    width: fit-content;
    display: block;
    transition: all 0.3s;
}

.btnTrain a:hover {
    box-shadow: -2px 3px 0px #4381c2;
}

/* ************************************************************************************ */
.dropdown-menu {
    display: none !important;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-menu li a {
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    text-align: right;
    cursor: pointer;
}

.dropdown-menu li:hover a {
    color: var(--color-primary);
}

.dropdown-menu li:not(:last-child) {
    border-bottom: 1px solid #f4f4f4;
}

li:hover .dropdown-menu {
    display: block !important;
}

.menu {
    display: none;
}

.menu i {
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    color: var(--color-primary);
}

@media (max-width: 994px) {
    .menu {
        display: block;
    }

    .navigation {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.53);
        z-index: 100;
        display: none !important;
    }

    .menu_nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;
        height: 100%;
        background-color: #fff;
        z-index: 1000;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .menu_nav a {
        color: #111 !important;
    }

    .active_menu {
        display: block !important;
    }
    .menu svg{
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 767px) {
    .imgLogo {
        width: 127px;
        /* height: 24px; */
    }

    .lside .btnTrain a {
        font-size: 0.75rem;
        padding: 10px 20px;
        border-radius: 8px;
    }

    .lside .chLang {
        gap: 4px;
        font-size: 14px;
    }

    .chLang i {
        font-size: 20px;
    }

    .menu_nav li a {
        font-size: 14px !important;
    }

    .btnTrain a {
        font-size: 12px;
        padding: 4px 18px 5px;

    }
}


.headerEn {
    direction: ltr;
}

.chLangEn::after {
    left: 45px !important;
}

.changeLanguage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.chLang {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.chLang span {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
@media (max-width: 500px) {
    .imgLogo img {
        max-width: 80%;
    }

    .menu i {
        font-size: 1rem;
    }

    .lside .btnTrain a {
        font-size: 0.5rem;
        padding: 10px 7px;
        border-radius: 8px;
    }

    .chLang span {
        font-size: 16px;
    }
    .chLang svg{
        width: 20px;
        height: 20px;
    }
}
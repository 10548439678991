.CommonImg{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.CommonImg img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
@media(max-width:994px){
    .CommonImg{
        height: 40vh;
    }
        .CommonImg img{
            object-fit: cover;
        }
}
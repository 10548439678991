footer{
    background: #202328;
    padding: 30px 0 0;  
    position: relative; 
    background: url('../../assets/images/footerbg.png');
    background-position: center;
    background-repeat: no-repeat;
     background-size: cover;
}
.footerContent p{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    width: 50%;
}

.iconsSocial .icon{
 width: 30px;   
}
.iconsSocial img{
    max-width: 100%;
}
.iconsSocial a{
    text-decoration: none;
}
.iconsSocial{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
} 
.footerContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.footerLast{
border-top: 1px solid #707070;
margin-top: 20px;
padding: 20px 0;
}
.footerLast .container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerLast p{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin: 0;
}
.footerLast ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.footerLast ul li a{
    text-decoration: none;
    color: #fff;
}
@media(max-width:767px){
.footerContent p {
    margin-top: 0;
    width: 100%;
    font-size: 14px;
}
.footerLast .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.footerLast p{
    font-size: 12px;
}
.footerLast ul{
    gap: 15px;
}
.footerLast ul li a{
    font-size: 12px;
}
}

/* 
*/
.EventList {
  position: relative;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 30px; */
}
.EventList .EventCard:not(:last-of-type) {
  border-bottom: 1px solid #000;
}
.EventCard {
    width: 100%;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  gap: 70px; */
  gap: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 60px 0;
}
.EventCard_content{
    width: 100%;
    flex-basis: 55%;
}
.EventCard_img{
  width: 100%;
  flex-basis: 45%;
  display: flex;
    justify-content: flex-end;
}
.EventCard_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.EventCard .EventCard_content > h2 {
  font-size: 42px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 20px;
}
.EventCard .EventCard_content > h2::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -10px;
  width: 50%;
  height: 1px;
  background: #707070;
  z-index: 1;
}
.EventCard .EventCard_content > h2::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  width: 25%;
  height: 6px;
  background: #e3bf5c;
  z-index: 2;
}

.EventCard_titleAr::after{
  right: 0 !important;
}
.EventCard_titleAr::before{
    right: 0 !important;

}
.EventCard .EventCard_content > p {
  width: 100%;
  overflow: hidden;
  margin: 30px 0 0;
  font-size: 21px;
  color: #484848;
  /* display: inline; */
}
.placeTime {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 70px;
  margin-top: auto;
}
.placeTime .place h3,
.placeTime .time h3 {
  color: #285680;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
.placeTime .place p,
.placeTime .time p {
  color: #414141;
  font-size: 21px;
  font-weight: 500;
}
.ExploreMore {
  display: block;
  text-decoration: none;
  background: #2a577f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: fit-content;
  padding: 20px 50px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px
}
.seeMore{
  color: #285680;
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  font-weight: bold;
  /* display: inline; */
}
@media (max-width: 994px) {
  .EventList {
    padding: 40px 20px;
    overflow: hidden;
  }
    .EventCard_img img{
      max-width: 100%;
    }
    .EventCard{
      flex-direction: column;
    }
}
@media (max-width: 767px) {

     .EventList {
    padding: 20px;
  }
  .EventCard{
    gap: 60px;
  }
  .EventCard .EventCard_content > h2 {
    font-size: 24px;
  }
  .EventCard .EventCard_content > p {
    font-size: 14px;
  }
  .placeTime{
    flex-direction: column;
    gap: 15px;
  }
  .placeTime .place h3,
  .placeTime .time h3 {
    font-size: 16px;
  }
  .placeTime .place p,
  .placeTime .time p {
    font-size: 14px;
  }
  .ExploreMore{
    padding: 15px 50px;
    font-size: 14px;
  }
}

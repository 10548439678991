/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=El+Messiri:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');
:root {
  --color-primary: #0A2B47;
  --color-secondary: #E3BF5C;
  --color-text: #fff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

body {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  min-height: 100vh;
}

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

li {
  margin: 0;
  padding: 0;
}

.whatsapp-button {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 50000;
  cursor: pointer;

}

.whatsapp-button a {
  color: #ef4444;
  /* Text color for the link */
}


/* LoadingPage.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  /* or any background color you prefer */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4caf50;
  /* primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
main{
    overflow: hidden !important;
}